import React from "react";
import ReactDOM from "react-dom";
import SwaggerUI from "swagger-ui-react";
import yamlFile from "./swagger.yaml";

import "swagger-ui-react/swagger-ui.css";
function App() {
  return (
    <div className="App">
      <SwaggerUI url={yamlFile} />
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
